
const VIRTUAL_PAGES = {
  "click": { virtualPage: "/metrics/click/", virtualTitle: "!#[Клик по]: " },
  "scroll": { virtualPage: "/metrics/scroll/", virtualTitle: "!#[Скролл до]: " },
  "popup": { virtualPage: "/metrics/popup/", virtualTitle: "!#[Открытие popup]: " },
  "images": { virtualPage: "/metrics/images/", virtualTitle: "!#[Увеличение изображения]: " },
  "iframe": { virtualPage: "/metrics/iframe/", virtualTitle: "!#[Просмотрен iframe]: " },
}

function isEnableMetrics() {
  return window.MetricsCounter
}

function sendEvent(event, id) {
  if (!isEnableMetrics()) return;
  const virtualPage = `${VIRTUAL_PAGES[event].virtualPage}${id}`
  const virtualTitle = `${VIRTUAL_PAGES[event].virtualTitle}${id}`
  var mOpts = {
    title: virtualTitle,
    referer: window.location.href,
  };
  setTimeout(() => {
    ym(window.MetricsCounter, 'hit', virtualPage, mOpts);
  }, 1000)
}

function clickEvent(event) {
  if (!isEnableMetrics()) return;
  const el = event.target.closest("[data-metrics-id]")
  if (!el) return;
  const id = el.dataset.metricsId
  let events = el.dataset.metricsEvents
  events && (events = events.split(","))
  if (!events.includes("click")) return;
  sendEvent("click", id)
}

function createMetricsPlugin() {
  document.addEventListener("click", clickEvent);

  Alpine.magic('sendEvent', () => {
    return (event, id) => sendEvent(event, id)
  })

  Alpine.directive("metrics", (el, { value, modifiers, expression }, { Alpine, effect, cleanup, evaluate }) => {
    let events;
    events = Object.keys(VIRTUAL_PAGES).filter(k => modifiers.includes(k))

    if (events.includes("click")) {
      el.dataset.metricsId = expression;
      el.dataset.metricsEvents = events;
      if (modifiers[modifiers.indexOf("click") + 1] === "self") {
        let clickHandler = function (event) {
          clickEvent(event);
          event.stopPropagation();
        };
        el.addEventListener("click", clickHandler);
        cleanup(() => {
          el.removeEventListener('click', clickHandler);
        })
      }
    }

    if (events.includes("scroll")) {
      el.setAttribute("x-intersect.threshold.05.once", `$sendEvent('scroll', '${expression}')`)
    }
  })
}

export { createMetricsPlugin, sendEvent, isEnableMetrics };