import { createMetricsPlugin, sendEvent, isEnableMetrics } from "./metrics_plugin"
import { createBookingMetricsPlugin } from "./booking_metrics_plugin"
// import { createModalPlugin } from "./modal_plugin"

const ZOOMABLE_CONTAINER = `<div x-data="$store.__zoomable" x-bind="container" class="fixed inset-0 z-[99] flex items-center justify-center bg-black bg-opacity-50 select-none cursor-zoom-out"><div class="relative flex justify-center items-center w-11/12 h-[90%]" id="__zoomable_container"></div></div>`

const IFRAME_CONTAINER = `<div x-data="$store.__iframe_data" x-bind="container" :id="containerId" class="fixed inset-0 z-[9999] py-20 px-0 sm:p-12 w-auto h-auto flex justify-center items-stretch md:text-lg bg-opacity-60 bg-black"><button x-bind="button" class="absolute top-0 right-0 z-50 flex items-center justify-center mt-4 mr-2 sm:mt-2 sm:mr-2 text-gray-50 sm:hover:text-gray-950 rounded-full sm:hover:bg-gray-50 transition-colors duration-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-12 h-12 sm:w-9 sm:h-9"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg></button><div x-bind="wrapper" class="relative flex-1 p-4 bg-white sm:rounded-2xl"><iframe x-bind="iframe" frameborder="0" class="w-full h-full"></iframe></div></div>`

function createZoomablePlugin() {
    Alpine.store('__zoomable', {
        createZoomableContainer() {
            const el = document.createElement("div")
            el.innerHTML = ZOOMABLE_CONTAINER
            document.body.appendChild(el.firstElementChild)
            this.idZoomableContainer = "__zoomable_container"
        },
        idZoomableContainer: null,
        isShow: false,
        isActive: false,

        idTouch: null,
        startMoved: null,
        moveGap: 200,
        needClose: false,

        translate(gap) {
            document.getElementById(this.idZoomableContainer).parentElement.style.transform = `translateY(${gap}px)`
        },

        open(event) {
            const el = event.target;
            // if (el.tagName !== "IMG") return;
            // if (document.getElementById(this.idZoomableContainer).children.length) return;
            if (this.isShow) return;
            let picture = el.closest('picture')
            if (!picture) {
                const pictures = el.getElementsByTagName("picture")
                if (pictures.length > 1) return;
                picture = pictures[0]
            }
            if (!picture) return;

            picture = picture.cloneNode(true)

            picture.getElementsByTagName('img')[0].setAttribute("class", "absolute top-0 left-0 w-full h-full  object-contain")
            picture.getElementsByTagName('img')[0].removeAttribute("x-zoomable")
            Array.from(picture.getElementsByTagName('source')).forEach(e => { e.setAttribute('sizes', '100vw') })

            document.getElementById(this.idZoomableContainer).appendChild(picture)

            this.translate(0);
            this.idTouch = null;
            this.startMoved = null;
            this.needClose = false;

            this.isActive = true
            this.isShow = true
            // setTimeout(() => { this.isShow = true }, 100)

            history.pushState(null, null)
            isEnableMetrics() && (() => {
                const imageName = picture.getElementsByTagName("img")[0].dataset.imageUuid
                sendEvent("images", imageName || "undefined")
            })()
        },
        close() {
            this.needClose = false;
            this.isShow = false
            setTimeout(() => { this.isShow || (this.isActive = false) }, 250)
            // document.getElementById(this.idZoomableContainer).children[0].remove()
            Array.from(document.getElementById(this.idZoomableContainer).children).map(ch => ch.remove())
        },
        container: {
            "x-cloak": true,
            "x-show"() {
                return this.isShow

            },
            "@click"() {
                this.isShow && this.close()
            },
            "@keydown.window.escape"() {
                this.isShow && this.close()
            },
            "@popstate.window"() {
                this.isShow && this.close()
            },
            "x-trap.inert.noscroll"() {
                return this.isActive
            },
            "@touchstart.passive"(event) {
                if (!this.idTouch) {
                    this.idTouch = event.changedTouches[0].identifier
                    this.startMoved = event.changedTouches[0].pageY
                }
            },
            "@touchmove.passive"(event) {
                if (event.touches.length == 1) {
                    const touch = [...event.touches].find(t => t.identifier == this.idTouch)
                    if (touch) {
                        const gap = touch.pageY - this.startMoved
                        this.needClose = Math.abs(gap) >= this.moveGap
                        this.translate(gap)
                    }
                }
            },
            "@touchend.passive"(event) {
                const touches = [...event.changedTouches];
                let touch = touches.find(t => t.identifier == this.idTouch)
                if (touch) {
                    this.idTouch = null;
                    this.startMoved = null;
                    this.needClose && this.close()
                    this.translate(0);
                }
            },
            "x-transition:enter": "transition ease-in-out duration-300",
            "x-transition:enter-start": "opacity-0",
            "x-transition:enter-end": "opacity-100",
            "x-transition:leave": "transition ease-in-in duration-300",
            "x-transition:leave-start": "opacity-100",
            "x-transition:leave-end": "opacity-0",

        },
    });
    Alpine.directive('zoomable', (el, { value, modifiers, expression }, { Alpine, effect, cleanup, evaluate }) => {
        const store = Alpine.store("__zoomable")
        if (!store.idZoomableContainer) {
            store.createZoomableContainer()
        }

        let click = function (event) {
            store.open(event);
            // event.stopPropagation();
        };
        el.addEventListener('click', click);
        cleanup(() => {
            el.removeEventListener('click', click);
        })
    });
}

function _deleteTooltip(id) {
    const tooltip = document.getElementById(id);
    tooltip && tooltip.remove()
}

function createTooltopPlugin() {
    Alpine.directive('tooltip', (el, { value, modifiers, expression }, { Alpine, effect, cleanup, evaluate }) => {
        let tooltipContainer, isWhiteTheme, noShowTooltip = false;
        const tooltipId = evaluate("$id('tooltip')")

        isWhiteTheme = modifiers.includes("theme") && modifiers[modifiers.indexOf("theme") + 1] === "white"
        const themeStyle = isWhiteTheme ? "bg-white text-black" : "bg-black text-white"

        const tooltipHTML = `<p x-data="{show: false}" x-show="show" x-transition.duration.300ms x-transition.delay.300ms x-cloak x-anchor.${modifiers.join(".")}="document.querySelector('[data-tooltip=${tooltipId}]')"  x-init="setTimeout(function(){ show = true; }, 1);" id="${tooltipId}"class="absolute px-2 py-1 rounded-md shadow-sm ${themeStyle}">${expression}</p>`

        if (!(tooltipContainer = document.querySelector("[data-tooltip-container]"))) {
            tooltipContainer = document.createElement("div")
            tooltipContainer.dataset.tooltipContainer = true
            document.body.appendChild(tooltipContainer)
        }

        let mouseEnter = function (event) {
            noShowTooltip || (tooltipContainer.innerHTML = tooltipHTML)
        };

        let mouseLeave = function (event) {
            _deleteTooltip(event.target.dataset.tooltip)
            noShowTooltip = false;
        };

        let touchStart = function (event) {
            noShowTooltip = true;
        }
        let click = function (event) {
            _deleteTooltip(event.target.closest("[data-tooltip]").dataset.tooltip)
        }

        el.dataset.tooltip = tooltipId;

        el.addEventListener('mouseenter', mouseEnter);
        el.addEventListener('mouseleave', mouseLeave);
        el.addEventListener('touchstart', touchStart, { passive: true });
        el.addEventListener('click', click);

        cleanup(() => {
            el.removeEventListener('mouseenter', mouseEnter);
            el.removeEventListener('mouseleave', mouseLeave);
            el.removeEventListener('touchstart', touchStart);
            el.removeEventListener('click', click);
        })

    })
}

function createIframeContainer(Alpine) {
    const store = Alpine.store("__iframe_data")
    const el = document.createElement("div")
    el.innerHTML = IFRAME_CONTAINER
    document.body.appendChild(el.firstElementChild)
    store.isСreated = true;
}

function createIframePlugin() {
    Alpine.store("__iframe_data", {
        iframeLink: "",
        _show: false,
        customClass: "",
        isСreated: false,
        containerId: "__iframe_container",
        isShowIframe() {
            return this._show
        },
        openIframe(link, customClass = "", metricsId = "") {
            this.customClass = customClass
            this.iframeLink = link;
            this._show = true;
            metricsId && isEnableMetrics() && sendEvent("iframe", metricsId)
        },
        closeIframe() {
            this._show = false;
            this.iframeLink = "";
        },
        container: {
            "x-cloak": true,
            "x-show"() {
                return this.isShowIframe()
            },
            "@keydown.window.escape"() {
                return this.closeIframe()
            },
            "@popstate.window"() {
                return this.closeIframe()
            },
        },
        button: {
            "@click"() {
                this.closeIframe()
            },
        },
        wrapper: {
            "x-cloak": true,
            "x-show"() {
                return this.isShowIframe()
            },
            "@click.outside"() {
                this.closeIframe()
            },
            "x-trap.inert.noscroll"() {
                return this.isShowIframe()
            },
            ":class"() {
                return this.customClass
            },
            "x-transition:enter": "ease-out duration-300",
            "x-transition:enter-start": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
            "x-transition:enter-end": "opacity-100 translate-y-0 sm:scale-100",
            "x-transition:leave": "ease-in duration-200",
            "x-transition:leave-start": "opacity-100 translate-y-0 sm:scale-100",
            "x-transition:leave-end": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        },
        iframe: {
            "x-cloak": true,
            "x-show"() {
                return this.isShowIframe()
            },
            ":src": "iframeLink",
            // "x-trap.inert.noscroll"() {
            //     return this.isShowIframe()
            // },
        },
    });
    Alpine.directive('iframe-modal', (el, { value, modifiers, expression }, { Alpine, effect, cleanup, evaluate }) => {
        const store = Alpine.store("__iframe_data")
        let params;
        if (!expression) {
            expression = el.getAttribute("x-data")
        }
        params = evaluate(expression)

        if (typeof params === 'string' || params instanceof String) {
            params = { link: params }
        }
        if (!store.isСreated) {
            createIframeContainer(Alpine);
        }
        let click = function (event) {
            store.openIframe(params.link, params.customClass || "", params.metricsId || "");
            // event.stopPropagation();
        };
        el.addEventListener('click', click);
        cleanup(() => {
            el.removeEventListener('click', click);
        })
    });
}

document.addEventListener('alpine:init', () => {
    createZoomablePlugin();
    createTooltopPlugin();
    createIframePlugin();
    createMetricsPlugin();
    createBookingMetricsPlugin();
    // createModalPlugin();
})