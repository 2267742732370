document.addEventListener('alpine:init', () => {
    Alpine.data('submenu', (hoverEnable = true, anchorModifiers = "") => {
        anchorModifiers = anchorModifiers && ("." + anchorModifiers)
        return {
            hover: hoverEnable,
            _opened: new Set(),
            isOpen(id) {
                return this._opened.has(id)
            },
            closingTimers: new Array(),
            open(id) {
                this._opened.forEach((e) => this.close(e))
                clearTimeout(this.closingTimers[id])
                this._opened.add(id)
            },
            close(id) {
                clearTimeout(this.closingTimers[id])
                this._opened.delete(id)
            },
            closeLater(id) {
                this.closingTimers[id] = setTimeout(() => { this.close(id) }, 300)
            },
            toggle(id) {
                this.isOpen(id) ? this.close(id) : this.open(id)
            },
            container: {
                "x-data": "{ id: $id('submenu') }",
                "@mouseenter.prevent"() {
                    this.hover && this.open(this.id)
                },
                "@mouseleave.prevent"() {
                    this.hover && this.closeLater(this.id)
                },
                ":id"() {
                    return this.id
                }

            },
            trigger: {
                "x-ref": "trigger",
                "@click.prevent"() {
                    this.hover ? this.open(this.id) : this.toggle(this.id)
                },
                "@click.outside.throttle.1000ms"() {
                    this.close(this.id)
                },
                "@touchend.throttle.prevent"() {
                    this.toggle(this.id)
                },
                ":aria-expanded"() {
                    return this.isOpen(this.id)
                },
            },
            item: {
                ":aria-hidden"() {
                    return !this.isOpen(this.id)
                },
                "x-show"() {
                    return this.isOpen(this.id)
                },
                "@keydown.window.escape"() {
                    this.close(this.id)
                },
                // "x-trap"() {
                //     return this.isOpen(this.id)
                // },
                "x-transition:enter": "transition ease-out duration-300 transform",
                "x-transition:enter-start": "opacity-0 -translate-y-2",
                "x-transition:enter-end": "opacity-100 translate-y-0",
                "x-transition:leave": "transition ease-out duration-300",
                "x-transition:leave-start": "opacity-100",
                "x-transition:leave-end": "opacity-0",
                [`x-anchor${anchorModifiers}`]: "$refs.trigger",
            },
        }
    });
    Alpine.data('lazyLoadScript', (urlScript) => ({
        loadScript: false,
        url: urlScript,
        scriptSrc: '',
        load() {
            !this.loadScript && (this.scriptSrc = this.url, Alpine.nextTick(() => { this.loadScript = true }))
        },
    }));

    // Alpine.data('servicePrice', () => ({
    //     iframeLink: "",
    //     reviewLink: "",
    //     _show: false,
    //     isReviews: false,
    //     isShowIframe() {
    //         return this._show
    //     },
    //     isShowReview() {
    //         return this._show && this.isReviews
    //     },
    //     isShowGallery() {
    //         return this._show && !this.isReviews
    //     },
    //     openIframe(link, isReviews = false) {
    //         if (this.isReviews = isReviews) {
    //             this.reviewLink = link;
    //         } else {
    //             this.iframeLink = link;
    //         }
    //         this._show = true;
    //     },
    //     closeIframe() {
    //         this._show = false;
    //     },
    // }));
})


// Alpine.data('gallery', () => ({
//     idImageContainer: null,
//     isShow: false,
//     open(el, idImageContainer) {
//         this.idImageContainer = idImageContainer
//         let picture = el.closest('picture')
//         if (!picture) return;

//         picture = picture.cloneNode(true)

//         picture.getElementsByTagName('img')[0].setAttribute("class", "absolute top-0 left-0 w-full h-full  object-contain")
//         Array.from(picture.getElementsByTagName('source')).forEach(e => { e.setAttribute('sizes', '100vw') })
//         document.getElementById(this.idImageContainer).appendChild(picture)
//         this.isShow = true
//         history.pushState(null, null)
//     },
//     close() {
//         this.isShow = false
//         document.getElementById(this.idImageContainer).children[0].remove()
//     }
// }));