import './alpine_store'
import './alpine_data'
import './alpine_plugins'

import { createMap } from './genereted/ymaps'

window.createMap = createMap

var lazyLoadInstance = new window.LazyLoad({ threshold: 800 });
window.lazyLoadInstance = lazyLoadInstance

window.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

document.addEventListener('alpine:initialized', () => {
    window.lazyLoadInstance.update()
})
window.Alpine.start()