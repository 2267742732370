
function clickBookingMetrics(event) {
  const target = event.target.closest("a[data-booking-metrics-group]")
  if (!target) return true;

  const newUrl = new URL(target.href);

  try {
    const clientId = window._ClientId;
    if (clientId) {

      window._BookingMetricsLabels[target.dataset.bookingMetricsGroup].map(label => {
        newUrl.searchParams.set(label.label_name, label.label_value)
      })

      newUrl.searchParams.set("utm_content", clientId);

      target.href = newUrl;
    }

  } catch (error) {
    return true;
  }
  return true;
}

function createBookingMetricsPlugin() {
  Alpine.directive('book-online', (el, { value, modifiers, expression }, { Alpine, effect, cleanup, evaluate }) => {
    el.addEventListener('click', clickBookingMetrics);
    el.dataset.bookingMetricsGroup = expression
    cleanup(() => {
      el.removeEventListener('click', clickBookingMetrics);
    })
  });
}

export { createBookingMetricsPlugin };
