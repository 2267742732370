const maps =  {
    "state": {
        "center": [55.772859, 38.44655],
        "zoom": 14,
        "type": "yandex#map",
        "controls": ["fullscreenControl", "zoomControl", "typeSelector"]
    },
    "options": {
        "autoFitToViewport": "always",
        "minZoom": 6
    },
    "geoObjects": {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "id": 0,
                "properties": { "balloonContent": "Ногтевая студия: </br>ул. Мира, 24, корп. 1, Электросталь", "description": "", "iconContent": "", "iconCaption": "Студия Дарьи Даниловой" },
                "geometry": { "type": "Point", "coordinates": [55.772859, 38.44655] },
                "options": { "zIndex": 800000000, "order": 800000000, "preset": "islands#redHeartIcon" }
            }
        ]
    }
}

const mobilePanelStyles = {
    alignItems: 'center',
    boxSizing: 'border-box',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '22px',
    // fontFamily: 'Arial,sans-serif',
    opacity: '0.0',
    padding: '25px',
    textAlign: 'center',
    transition: 'opacity .3s',
    touchAction: 'auto'
};


function createMap(ymaps) {
    ymaps.ready(() => {

        var myMap = new ymaps.Map('ymap', maps.state, maps.options);


        var eventsPaneEl = myMap.panes.get('events').getElement();
        Array.prototype.forEach.call(Object.keys(mobilePanelStyles), function (name) {
            eventsPaneEl.style[name] = mobilePanelStyles[name];
        });

        myMap.controls.get('fullscreenControl').events.add('fullscreenenter', function (event) {
            history.pushState(null, null)
        });

        window.addEventListener("popstate", (event) => {
            const fullscreenControl = myMap.controls.get('fullscreenControl')
            if (fullscreenControl.state.get('fullscreen')) {
                fullscreenControl.exitFullscreen()
            }
        });

        myMap.behaviors.disable('scrollZoom');

        myMap.controls.get('fullscreenControl').events.add('fullscreenenter', function (event) {
            myMap.behaviors.enable('scrollZoom');
            window.isMobile && myMap.behaviors.enable('drag');
        });

        myMap.controls.get('fullscreenControl').events.add('fullscreenexit', function (event) {
            myMap.behaviors.disable('scrollZoom');
            window.isMobile && myMap.behaviors.disable('drag');
        });


        if (window.isMobile) {
            myMap.behaviors.disable('drag');

            ymaps.domEvent.manager.add(eventsPaneEl, 'touchmove', function (event) {
                if (event.get('touches').length === 1 && !myMap.controls.get('fullscreenControl').state.get('fullscreen')) {
                    eventsPaneEl.style.transition = 'opacity .3s';
                    eventsPaneEl.style.background = 'rgba(0, 0, 0, .45)';

                    eventsPaneEl.textContent = 'Чтобы переместить карту проведите по ней двумя пальцами';
                    eventsPaneEl.style.opacity = '1';
                }
            });
            ymaps.domEvent.manager.add(eventsPaneEl, 'touchend', function () {
                eventsPaneEl.style.transition = 'opacity .8s';
                eventsPaneEl.style.opacity = '0';
            });
        }
        const objectManager = new ymaps.ObjectManager();
        objectManager.add(maps.geoObjects);

        myMap.geoObjects.add(objectManager);

        ymaps.myMap = myMap

    })
}


export { createMap };



