import { sendEvent, isEnableMetrics } from "./metrics_plugin"

const MODAL_CONTAINER_ID = "__modal_container"
const MODAL_CONTAINER = `<div x-data id="${MODAL_CONTAINER_ID}" x-bind="$store.modal.shared_container()" class="relative w-auto h-auto"></div>`

function createModalContainer(Alpine) {
  const store = Alpine.store("modal")
  const el = document.createElement("div")
  el.innerHTML = MODAL_CONTAINER
  document.body.appendChild(el.firstElementChild)
  store.isСreated = true;
}

document.addEventListener('alpine:init', () => {
  // Alpine.store('recordingWindow', {
  //   _open: new Set(),
  //   isOpen(name) {
  //     return this._open.has(name)
  //   },
  //   open(name, metricsId = "") {
  //     this._open.add(name)
  //     history.pushState(null, null)
  //     metricsId && isEnableMetrics() && sendEvent("popup", metricsId)
  //   },
  //   close(name) {
  //     this._open.delete(name)
  //     // this._open = false
  //   },
  // });
  Alpine.store('modal', {
    // init() {
    //   this.isСreated || createModalContainer(Alpine);
    // },
    isСreated: false,
    _open: new Array(),
    _lastOpened: "",
    isOpen(name = "") {
      return this._open.includes(name)
    },
    isUpModal(name = "") {
      return this._open.slice(-1) == name
    },
    open(name = "", metricsId = "") {
      if (!name) return;
      this._open.push(name)
      this._lastOpened = name
      history.pushState(null, null)
      metricsId && isEnableMetrics() && sendEvent("popup", metricsId)
    },
    closeUp() {
      this._open.pop()
    },
    close(name = "") {
      if (!name) {
        this._open.pop()
      } else {
        this._open.pop(name)
      }
    },

    shared_container() {
      const extThis = this;
      return {
        "@keydown.escape.window"() {
          extThis.close()
        },
        "@popstate.window"() {
          extThis.close()
        },
      }
    },

    container(name) {
      const extThis = this;
      return {
        "x-show"() {
          return extThis.isOpen(name)
        },

        "@click.self"() {
          extThis.isOpen(name) && extThis.close(name)
        },
        "x-cloak": true,
        ":class"() {
          return {
            "z-[9999]": extThis.isUpModal(name),
            "z-50": !extThis.isUpModal(name),
          }
        },
      }
    },
    wrapper(name) {
      const extThis = this;
      return {
        "x-show"() {
          return extThis.isOpen(name)
        },
        "x-trap.inert.noscroll"() {
          return extThis.isOpen(name)
        },
        "x-cloak": true,

        "x-transition:enter": "ease-out duration-300",
        "x-transition:enter-start": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        "x-transition:enter-end": "opacity-100 translate-y-0 sm:scale-100",
        "x-transition:leave": "ease-in duration-300",
        "x-transition:leave-start": "opacity-100 translate-y-0 sm:scale-100",
        "x-transition:leave-end": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
      }
    },
    template: {
      "x-teleport": `#${MODAL_CONTAINER_ID}`,
    },
  });
  createModalContainer(Alpine);
})